<template>
  <div class="">
    <!-- テーブルAREA -->
    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
        <div>
          <span class="header-title">患者一覧</span>
        </div>
      </CCardHeader>
    </CCard>

    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
            <template slot="tools">
              <CButton v-if="isProvider()" size="sm" color="warning mr-1"  variant="outline" :pressed="allBranchFlg" @click="toggleAllBranch">全事業所</CButton>

<!--              <CButton color="info" size="sm" class="m-1" style="height:35px;" v-if="isAdministrator()" @click="csvDownload">CSVダウンロード</CButton>-->

<!--              <CButton size="sm" color="warning mr-1"  variant="outline" :pressed="archiveFlg"-->
<!--                       @click="toggleArchive"-->
<!--                       v-c-tooltip="{content: 'アーカイブ済みの表示を切り替える',placement:'bottom' }">アーカイブ-->
<!--              </CButton>-->
            </template>
        </MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            disabled-hover
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false"
        >
            <!-- HEADER ソート可能にするために 必要-->
            <template slot-scope="{ column,index }" slot="hospitalNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
            <template slot-scope="{ column,index }" slot="karuteHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
            <template slot-scope="{ column,index }" slot="nameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
            <template slot-scope="{ column,index }" slot="ageHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
            <template slot-scope="{ column,index }" slot="genderHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
<!--            <template slot-scope="{ column,index }" slot="doctorNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>-->
            <template slot-scope="{ column,index }" slot="agencyNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
            <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
            <template slot-scope="{ column,index }" slot="patientIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>


            <template slot-scope="{ column,index }" slot="userNameHeader"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
            <template slot-scope="{ column,index }" slot="roleHeader"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
            <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>


          <template slot-scope="{ column,index }" slot="frHead">
            <div>
              <div>酸素濃縮装置</div>
<!--              <div>直近30日の使用時間</div>-->
            </div>
          </template>

          <template slot-scope="{ column,index }" slot="fr30hHead">
            <div>
              <div>直近30日の使用時間</div>
              <div>(h)</div>
            </div>
          </template>


            <template slot-scope="{ column,index }" slot="puLast30hHead">
              <div>
                <div>SpO₂</div>
                <div>(%)</div>
              </div>
            </template>

            <template slot-scope="{ column,index }" slot="puPRHead">
              <div>
                <div>脈拍数</div>
                <div>(回/分)</div>
              </div>
            </template>

            <template slot-scope="{ column,index }" slot="blUpDownHead">
              <div>
                <div>収縮期・拡張期</div>
                <div>(mmHg)</div>
              </div>
            </template>

            <template slot-scope="{ column,index }" slot="blBpmHead">
              <div>
                <div>脈拍数</div>
                <div>(回/分)</div>
              </div>
            </template>

          <template slot-scope="{ column,index }" slot="spo2ThresholdCountHead">
              <div class="thresholdInfo" @click="thresholdDialog(1)">
                <div>閾値外</div>
                <div>頻度<img src="img/info.png" width="10px" height="10px"/></div>
              </div>
          </template>

          <template slot-scope="{ column,index }" slot="prBpmThresholdCountHead">
            <div class="thresholdInfo" @click="thresholdDialog(2)" >
              <div>閾値外</div>
              <div>頻度<img src="img/info.png" width="10px" height="10px"/></div>
            </div>
          </template>


          <template slot-scope="{ column,index }" slot="blThresholdCountHead">
              <div class="thresholdInfo" @click="thresholdDialog(3)">
                <div>閾値外</div>
                <div>頻度<img src="img/info.png" width="10px" height="10px"/></div>
              </div>
          </template>
          <template slot-scope="{ column,index }" slot="blHeartThresholdCountHead">
            <div class="thresholdInfo" @click="thresholdDialog(4)" >
              <div>閾値外</div>
              <div>頻度<img src="img/info.png" width="10px" height="10px"/></div>
            </div>
          </template>
            <!-- TDの中身 -->
          <template slot-scope="{ row,index }" slot="hospitalName">
            <div class="text-left">{{row.hospitalName}}</div>
          </template>

<!--          <template slot-scope="{ row,index }" slot="karuteNo" >-->
<!--            <div class="text-left">-->
<!--              <span v-if="row.karuteNo != '*'"><a href="" @click.prevent.stop="idClick(row)">{{row.karuteNo}}</a></span>-->
<!--              <span v-else>{{row.karuteNo}}</span>-->
<!--            </div>-->
<!--          </template>-->


<!--          <template slot-scope="{ row,index }" slot="name" >-->
<!--              <div class="text-left">-->
<!--                <a href="" @click.prevent.stop="patientDialog(row)">{{row.name}}</a>-->
<!--              </div>-->
<!--            </template>-->

          <template slot-scope="{ row,index }" slot="name">
            <div v-if="index % 2 == 0" class="text-left">
              <span v-if="isAdministrator() || isDoctor() || (isProvider() && row.viewVitalFlg == true)">
                <a href="" @click.prevent.stop="idClick(row)">{{row.karuteNo}}</a>
              </span>
              <span v-else>{{row.karuteNo}}</span>
            </div>
            <div v-else class="text-left">
              <span style="font-weight: bold;font-size: 16px">
                <a href="" @click.prevent.stop="patientDialog(row)">{{ row.name }}</a></span>
            </div>
          </template>

            <template slot-scope="{ row,index }" slot="gender">
              <div v-if="index % 2 == 0" class="text-center">
                <span>{{ row.age }}</span>
              </div>
              <div v-else class="text-center">
                <span>{{ row.gender }}</span>
              </div>
            </template>


            <template slot-scope="{ row,index }" slot="agencyBranchName">
              <div v-if="index % 2 == 0" class="text-left">
                <span>{{ row.agencyName }}</span>
              </div>
              <div v-else class="text-left">
                <span>{{ row.agencyBranchName }}</span>
              </div>
            </template>

            <template slot-scope="{ row,index }" slot="fr30h">
              <div v-if="index % 2 == 0">
               <div>{{ row.fr }}</div>
              </div>
              <div v-else>{{ row.fr30h }}</div>
            </template>

            <template slot-scope="{ row,index }" slot="puLast30h">
              <div v-if="index % 2 == 0" class="text-center">
                <a href="" @click.prevent.stop="openThreathHoldModal(row)">{{ row.puLast30h }}</a>
              </div>
              <div v-else>
                {{ row.puDateTime }}
              </div>
            </template>

          <template slot-scope="{ row,index }" slot="puPR">
              <div  class="text-center">
                <a href="" @click.prevent.stop="openThreathHoldModal(row)">{{ row.puPR }}</a>
              </div>
            </template>

            <div class="abc"></div>
            <template slot-scope="{ row,index }" slot="blUpDown">
              <div v-if="index % 2 == 0">
                  <a href="" @click.prevent.stop="openThreathHoldModal(row)">{{ row.blUpDown }}</a>
              </div>
              <div v-else>
                <div><span>{{ row.blDateTime }}</span></div>
              </div>
            </template>


          <template slot-scope="{ row,index }" slot="blBpm">
            <div>
                <a href="" @click.prevent.stop="openThreathHoldModal(row)">{{ row.blBpm }}</a>
            </div>
          </template>

          <template slot-scope="{ row,index }" slot="patientId">
            <div class="text-center">
              <div>{{ row.patientId }}</div>
              <div v-if="isAdministrator() || isProvider() || isDoctor()">
<!--                <CButton size="sm" color="warning mr-1"-->
<!--                         @click="openArchiveModal(row.patientId)"-->
<!--                         >-->
<!--                </CButton>-->
                <CBadge size="sm" color="info mr-1" @click="openArchiveModal(row.patientId)">アーカイブ</CBadge>
              </div>
            </div>
          </template>
        </BeeGridTable>

        <CModal :show.sync="thresholdInfoDialog"
                size="lg"
                :centered="true"
                title="閾値外頻度について">
          <div style="font-size: 18px;">
            <CRow>
              <CCol>{{thresholdInfo}}</CCol>
            </CRow>
          </div>
          <template #footer-wrapper><span></span></template>
        </CModal>
        <CModal :show.sync="showPatientInfo"
                size="lg"
                :centered="true"
                title="患者詳細情報">
          <div style="font-size: 18px;">
            <CRow>
              <CCol col="3"><label>患者名</label></CCol>
              <CCol>{{patientInfo.lastName}} {{patientInfo.firstName}}</CCol>
            </CRow>

            <CRow>
              <CCol col="3"><label>カルテID</label></CCol>
              <CCol>{{patientInfo.karuteNo}}</CCol>
            </CRow>

            <CRow>
              <CCol col="3"><label>電話番号</label></CCol>
              <CCol>{{patientInfo.tel}}</CCol>
            </CRow>
            <CRow>
              <CCol col="3"><label>性別</label></CCol>
              <CCol>{{patientInfo.genderLabel}}</CCol>
            </CRow>
            <CRow>
              <CCol col="3"><label>住所</label></CCol>
              <CCol>{{patientInfo.address}}</CCol>
            </CRow>
            <CRow>
              <CCol col="3"><label>生年月日</label></CCol>
              <CCol>{{patientInfo.birthDay}}</CCol>
            </CRow>
            <CRow>
              <CCol col="3"><label>年齢</label></CCol>
              <CCol>{{patientInfo.age}}</CCol>
            </CRow>
            <CRow>
              <CCol col="3"><label>販売店名</label></CCol>
              <CCol>{{patientInfo.agencyName}}</CCol>
            </CRow>
            <CRow>
              <CCol col="3"><label>販売店事業所</label></CCol>
              <CCol>{{patientInfo.agencyBranchName}}</CCol>
            </CRow>
            <CRow>
              <CCol col="3"><label>担当医</label></CCol>
              <CCol>{{patientInfo.doctorName}}</CCol>
            </CRow>
          </div>

          <template #footer-wrapper><span></span></template>
        </CModal>
        <CModal :show.sync="showThreathHoldDialog"
                :centered="true"
                title="閾値設定">
          <div style="font-size: 18px;">
            <CRow>
              <CCol><label style="font-weight: bold">パルスオキシメータ</label></CCol>
            </CRow>
            <CRow>
              <CCol class="ml-3">SpO2(％)</CCol>
            </CRow>
            <CRow class="pb-0">
              <CCol col="12 pl-5">
                <CInput class="ml-2 mb-0" horizontal label="下限" @keypress="isNumber($event)" v-model.number="thresholdFormData.spoDown"
                ></CInput><!--                placeholder="35～100を入力"-->
              </CCol>
            </CRow>
            <!-- PR(BPM)-->
            <CRow class="pb-0">
              <CCol class="ml-3 ">脈拍数(回/分)</CCol>
            </CRow>
            <CRow class="pb-0">
              <CCol col="12 pl-5">
                <CInput class="ml-2 mb-0" horizontal label="上限" @keypress="isNumber($event)" v-model.number="thresholdFormData.prUp"></CInput>
              <!--  placeholder="25～250を入力"-->
              </CCol>
            </CRow>
            <CRow class="pb-0">
              <CCol col="12 pl-5">
                <CInput class="ml-2 mb-0" horizontal label="下限"  @keypress="isNumber($event)" v-model.number="thresholdFormData.prDown"></CInput>
                <!-- placeholder="25～250を入力"-->
              </CCol>
            </CRow>

            <CRow class="pb-0">
              <CCol><label style="font-weight: bold">血圧計</label></CCol>
            </CRow>
            <CRow class="pb-0">
              <CCol class="ml-3">血圧(mmHg)</CCol>
            </CRow>
            <CRow class="pb-0">
              <CCol col="12 pl-5 ">
                <CInput class="ml-2 mb-0" horizontal label="収縮期上限"  @keypress="isNumber($event)" v-model.number="thresholdFormData.blDown"></CInput>
              <!--   placeholder="0～299を入力"-->
              </CCol>
            </CRow>
            <CRow class="pb-0">
              <CCol col="12 pl-5">
                <CInput class="ml-2" horizontal label="拡張期上限"  @keypress="isNumber($event)" v-model.number="thresholdFormData.blUp"></CInput>
                <!--  placeholder="0～299を入力"-->
              </CCol>
            </CRow>

            <CRow class="pb-0">
              <CCol class="ml-3">脈拍数(回/分)</CCol>
            </CRow>
            <CRow class="pb-0">
              <CCol col="12 pl-5">
                <CInput class="ml-2 mb-0" horizontal label="上限"  @keypress="isNumber($event)" v-model.number="thresholdFormData.heartUp"></CInput>
              <!--  placeholder="40-180を入力"-->
              </CCol>
            </CRow>
            <CRow class="pb-0">
              <CCol col="12 pl-5">
                <CInput class="ml-2" horizontal label="下限"  @keypress="isNumber($event)" v-model.number="thresholdFormData.heartDown"></CInput>
              <!--  placeholder="40-180を入力"-->
              </CCol>
            </CRow>

          </div>
          <template #footer>
            <CButton size="sm" color="info" @click="showThreathHoldDialog = false">キャンセル</CButton>
            <CButton size="sm" color="danger" @click="saveThreshold()">登録</CButton>
          </template>
        </CModal>

        <CModal
            title="アーカイブ"
            :show.sync="archiveModal">
          <div>
            <CSelect
                label="アーカイブ理由選択"
                :options="archiveReasons"
                :value="archiveFormData.archiveReason"
                @update:value="setArchiveReasonValue"
            />

          </div>
          <template #footer>
            <CButton size="sm" color="info" @click="archiveModal = false">キャンセル</CButton>
            <CButton size="sm" color="danger" @click="archive()">登録</CButton>
          </template>
        </CModal>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
        name: 'PatientList',
        components:{MyBeeHeader,MyBeeSearchBar},
        mixins:[BeeMix],
        data() {
            return {
              showPatientInfo:false,
              showThreathHoldDialog:false,
              thresholdFormData:{},
              patientInfo:{},
              thresholdInfoDialog:false,
              searchBarPlaceHolder:'カルテID、患者名、担当医、販売店、販売店事業所、登録No、医療機関で検索',
              thresholdInfo:null,
              pagingParams: Object.assign(BeeMix.data().pagingParams,{sort: [{field: "karuteNo", sort: "desc"}]}),
              columns: [
                //チェックボックス hospitalNameHead,agencyNameHead,patientIdHead
                // {type: "selection",   width: 60,   maxWidth: 50,   align: "center", },
                {key:"hospitalName", title: '医療機関',slot:"hospitalName",headSlot:"hospitalNameHead",minWidth: 200,align: 'center',resizable: false,  },

                {key:"karuteNo", title: 'カルテID',align: 'center', slot:"karuteNo",headSlot: "karuteHead",resizable: false,
                  children:[{key:"name", title: '患者名', align: 'center',slot:"name"/*,headSlot:"nameHead"*/,resizable: false, },]
                },
                {key:"age",title:'年齢',minWidth:70, align: 'right',slot:"age",headSlot:"ageHead",resizable: false,
                  children: [{key:"gender",title:'性別',width:70, align: 'right',slot:"gender",headSlot:"genderHead",resizable: false}]
                },
                {key:"doctorName", title:'担当医', minWidth:100,maxWidth:200, align: 'center',resizable: false},
                {key:"fr",title:'酸素濃縮装置使用時間h',minWidth:160, align: 'center',slot:"fr",headSlot:"frHead" ,resizable: false,className:"point-col",
                  children: [{key: "fr30h",title: "(直近30日)",width: 160,minWidth: 160,headSlot:"fr30hHead",align: 'center',slot:"fr30h",hideFilter: true,resizable: false,className:"point-col",}]
                },
                {key:"pu", title:'パルスオキシメータ', minWidth: 180, align: 'center',resizable: false,className:"point-col",
                  children: [ {key: "puLast30h",title: "SpO₂(%)",width: 65,slot: "puLast30h",headSlot: "puLast30hHead",align: 'center',hideFilter: true,resizable: false,className:"point-col",},
                              {key: "spo2ThresholdCount",title: "閾値外回数",width: 90,headSlot:"spo2ThresholdCountHead",align: 'center',hideFilter: true,resizable: false,className:"point-col",},
                              {key: "puPR",title: "脈拍数(回/分)",width: 65,slot:"puPR",headSlot:"puPRHead",align: 'center',hideFilter: true,resizable: false,className:"point-col",},
                              {key: "prBpmThresholdCount",title: "閾値外回数",width: 90,headSlot:"prBpmThresholdCountHead",align: 'center',hideFilter: true,resizable: false,className:"point-col",},
                  ]
                },
                {key:"bl", title:'血圧計',/* minWidth: 180,*/ align: 'center',resizable: false,className:"point-col",
                  children: [
                      {title: "収縮期・拡張期(mmHg)",key: "blUpDown",width: 120,slot: "blUpDown",headSlot: "blUpDownHead",align: 'center',hideFilter: true,resizable: false,className:"point-col",},
                    {title: "閾値外回数",key: "blThresholdCount",headSlot: "blThresholdCountHead",width: 90,align: 'center',hideFilter: true,resizable: false,className:"point-col",},
                    {title: "脈拍数(回/分)",key: "blBpm",slot:"blBpm",headSlot: "blBpmHead",width: 80,align: 'center',hideFilter: true,resizable: false,className:"point-col",},
                    {title: "閾値外回数",key: "blHeartThresholdCount",headSlot: "blHeartThresholdCountHead",width: 90,align: 'center',hideFilter: true,resizable: false,className:"point-col",},
                  ]
                },
                {key:"patientId", title:'登録No',slot:"patientId",headSlot:"patientIdHead", width: 150/*,width:150*/, align: 'center',resizable: false},
                {key:"agencyName", title:'販売店', minWidth: 150,slot:"agencyName",headSlot:"agencyNameHead",align: 'center',resizable: false,
                children: [
                  {key:"agencyBranchName", title:'販売店事業所',slot:"agencyBranchName",headSlot:"agencyBranchNameHead",align: 'center', width: 150,resizable: false},
                ]},
                // {key:"op", title:'', align: 'center',resizable: false},
              ],
              archiveFlg:false,
              allBranchFlg:false,
              archiveModal:false,
              archiveReasons: [
                {value: "END", label: "死去"},
                {value: "MOVE", label: "転院"},
                {value: "RECOVERY", label: "回復"},
                {value: "OTHER", label: "その他"},
              ],
              archiveFormData:{patientId:null,archiveReason:null},
            }
        },
        mounted() {
          if(this.isDoctor()){
            this.hiddenColumn('hospitalName');
          }
        },
        methods: {
          goodPaging(isPageChange) {
            if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する

            // console.log("goodPaging",JSON.stringify({pagingForm:this.pagingParams,archiveFlg:this.archiveFlg}));
            this.isLoading = true;
            this.axios.post('/api/patient/search', {pagingForm:this.pagingParams,archiveFlg:this.archiveFlg,allBranchFlg:this.allBranchFlg}, {headers: {'Content-Type': 'application/json'}}).then(response => {
              // console.log("response.data.result.content.length",response.data.result.content.length);
              this.totalRecords = response.data.result.totalElements;
              let list = [];
              for(let i=0; i<response.data.result.content.length; i++){
                list.push(Object.assign({indexKey:Math.random().toString(32).substring(2)+i},response.data.result.content[i]));
                // list.push(Object.assign({indexKey:Math.random().toString(32).substring(2)+i},response.data.result.content[i]));
                list.push(
                    Object.assign({indexKey:"b"+i},
                        {
                          patientId:response.data.result.content[i]["patientId"],
                          name:response.data.result.content[i]["name"],
                          gender:response.data.result.content[i]["gender"],
                          fr30h:response.data.result.content[i]["fr30h"],
                          puDateTime:response.data.result.content[i]["puDateTime"],
                          blDateTime:response.data.result.content[i]["blDateTime"],
                          agencyBranchName:response.data.result.content[i]["agencyBranchName"],
                        })
                );
              }
              this.rows = list;
              //ページ件数
              this.pages = response.data.result.totalPages;
              this.topLeft();
            }).catch(error => console.log(error)
            ).finally(() => {
              this.isLoading = false;
            });
          },
          thresholdDialog(type){
            if(type == 3){
              this.thresholdInfo = "直近30日における統計上の測定値を分母に、閾値外のデータを分子にしています。なお、収縮期、拡張期血圧のいずれか超えると分子に1を加えています（or条件）"+
                  "表中の日時はデータ取得時間を示しています。";
            }else{
              this.thresholdInfo = "直近30日における統計上の測定値を分母に、閾値外のデータを分子にしています。" +
                  "表中の日時はデータ取得時間を示しています。";
            }

            this.thresholdInfoDialog = true;
          },
          setArchiveReasonValue(value) {
            this.archiveFormData.archiveReason = value;
          },
          openArchiveModal(patientId) {
            //権限がないものは何もしない
            if(!this.isAdministrator() && !this.isProvider() && !this.isDoctor()) {
              return;
            }
              this.archiveFormData.patientId = patientId;
              this.archiveFormData.archiveReason = this.archiveReasons[0].value;
              this.archiveModal = true;
          },
          saveThreshold(){
            //上下逆転チェック
            if(this.isDecimal(this.thresholdFormData.prDown) && this.isDecimal(this.thresholdFormData.prUp) && this.thresholdFormData.prDown > this.thresholdFormData.prUp){
              this.$swal("PR(bpm)の下限が上限を超えています。");
              return;
            }
            if(this.isDecimal(this.thresholdFormData.blDown) && this.isDecimal(this.thresholdFormData.blUp) && this.thresholdFormData.blDown < this.thresholdFormData.blUp){
              this.$swal("血圧収縮期の上限が拡張期の上限を超えています。");
              return;
            }
            if(this.isDecimal(this.thresholdFormData.heartDown) && this.isDecimal(this.thresholdFormData.heartUp) && this.thresholdFormData.heartDown > this.thresholdFormData.heartUp){
              this.$swal("心拍数の下限が上限を超えています。");
              return;
            }

            //範囲チェック
            if(this.isDecimal(this.thresholdFormData.spoDown)  &&  (this.thresholdFormData.spoDown < 35 || this.thresholdFormData.spoDown > 100)){
              this.$swal("SpO2の下限は35~100までの間で入力してください。");
              return;
            }
            if(this.isDecimal(this.thresholdFormData.prDown)  && (this.thresholdFormData.prDown < 25 || this.thresholdFormData.prDown > 250)){
              this.$swal("PR(bpm)の下限は25~250までの間で入力してください。");
              return;
            }
            if(this.isDecimal(this.thresholdFormData.prUp) && this.thresholdFormData.prUp < 25 || this.thresholdFormData.prUp > 250){
              this.$swal("PR(bpm)の上限は25~250までの間で入力してください。");
              return;
            }
            if(this.isDecimal(this.thresholdFormData.blDown) && this.thresholdFormData.blDown < 0 || this.thresholdFormData.blDown > 299){
              this.$swal("収縮期の上限は0~299までの間で入力してください。");
              return;
            }
            if(this.isDecimal(this.thresholdFormData.blUp) && this.thresholdFormData.blUp < 0 || this.thresholdFormData.blUp > 299){
              this.$swal("拡張期の上限は0~299までの間で入力してください。");
              return;
            }

            if(this.isDecimal(this.thresholdFormData.heartDown) && this.thresholdFormData.heartDown < 40 || this.thresholdFormData.heartDown > 180){
              this.$swal("心拍数の下限は40~180までの間で入力してください。");
              return;
            }
            if(this.isDecimal(this.thresholdFormData.heartUp) && this.thresholdFormData.heartUp < 40 || this.thresholdFormData.heartUp > 180){
              this.$swal("心拍数の上限は40~180までの間で入力してください。");
              return;
            }

            this.$swal({
              title: "確認",
              text: `閾値を登録しますか？`,
              buttons: true,
              dangerMode: true,
            }).then((result) => {
              if (result) {
                this.axios.post("/api/save_threshold",
                    this.thresholdFormData, {headers: {"Content-Type": "application/json"}}
                ).then(response => {
                  this.showThreathHoldDialog = false;
                }).catch(error => {
                  console.log('error', error.response.data);
                }).finally(() => {
                  console.log('finally');
                });
              }
            });

          } ,
          archive() {
            this.$swal({
              title: "確認",
              text: `アーカイブ処理を行いますか？`,
              buttons: true,
              dangerMode: true,
            }).then((result) => {
              if (result) {
                  this.axios.post("/api/patient/archive",
                      {
                        patientId: this.archiveFormData.patientId,
                        archiveReason: this.archiveFormData.archiveReason
                      }, {headers: {"Content-Type": "application/json"}}
                  ).then(response => {
                    this.goodPaging();
                    this.archiveModal = false;
                  }).catch(error => {
                    console.log('error', error.response.data);
                  }).finally(() => {
                    console.log('finally');
                  });
                }
            });
          },
          customSpan({ row, column, rowIndex, columnIndex }){
            if(rowIndex % 2 == 1) {
              if (["puLast30h"].includes(column.key)) {
                return {rowspan: 1, colspan: 4};
              }
              if (["spo2ThresholdCount","prBpmThresholdCount","puPR"].includes(column.key)) {
                return {rowspan: 0, colspan: 0};
              }

              if (["blUpDown"].includes(column.key)) {
                return {rowspan: 1, colspan: 4};
              }
              if (["blThresholdCount","blBpm","blHeartThresholdCount"].includes(column.key)) {
                return {rowspan: 0, colspan: 0};
              }
            }

            if(["gender","name","agencyBranchName","fr","pu","bl","fr30h","puLast30h","puPR","spo2ThresholdCount","blUpDown","blBpm","blThresholdCount","prBpmThresholdCount","blHeartThresholdCount"].includes(column.key)){
              return {rowspan: 1,colspan: 1};
            }
            if(rowIndex % 2 == 0){
              return {
                rowspan: 2,colspan: 1
              };
            }else{
              return {
                rowspan: 2,colspan: 0
              };
            }
          },
          idClick:function(row){
            // this.$router.push("/pages/patient/summary/"+row.patientId);
            // window.open(`/#/PatientSummaryWide/${row.patientId}`);
            window.open(`/#/PatientSummaryFixed/${row.patientId}`);

          },openThreathHoldModal(row){
            this.thresholdFormData.patientId = row.patientId;
            this.axios.get(`/api/get_threshold/${row.patientId}`).then(response => {
              console.log(JSON.stringify(response.data.result));
              if(response.data.result){
                this.thresholdFormData = response.data.result;
              }else{
                this.thresholdFormData = {patientId:row.patientId};
              }
              this.showThreathHoldDialog = true;
            }).catch(error => console.log(error)
            ).finally(() => this.isLoading = false);
          },
          patientDialog:function(row){

            this.axios.get(`/api/patient/${row.patientId}`).then(response => {
                console.log(JSON.stringify(response.data.result));
                this.patientInfo = response.data.result;
                if(this.patientInfo.gernder == "M"){
                  this.patientInfo.gender  = "男";
                }else if(this.patientInfo.gernder == "M"){
                  this.patientInfo.gender = "女";
                }else if(this.patientInfo.gernder == "O") {
                  this.patientInfo.gender = "その他";
                }
                this.showPatientInfo = true;

            }).catch(error => console.log(error)
            ).finally(() => this.isLoading = false);


          },csvDownload() {
              this.isLoading = true;
              this.axios.post('/api/patient/csvDownload',
                  {pagingForm:this.pagingParams,archiveFlg:null}
                  , {headers: {'Content-Type': 'application/json'},responseType: 'blob'}).then(
                      response => {

                        let url = window.URL.createObjectURL(new Blob([response.data]));
                        let link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', decodeURI(response.headers.filename));
                        document.body.appendChild(link);
                        link.click();
                      }).catch(error => console.log(error)
              ).finally(() =>{
                this.isLoading = false
              });
          },toggleArchive(){
            this.archiveFlg = !this.archiveFlg;
            this.goodPaging()
          },toggleAllBranch(){
            this.allBranchFlg = !this.allBranchFlg;
            this.goodPaging()
          }
        }
    }
</script>
<style scoped>
.thresholdInfo{
  cursor:pointer;
}
</style>